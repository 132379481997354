@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  background-color: #fafafa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.lexend-100 {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
}

.lexend-200 {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
}

.lexend-300 {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

.lexend-400 {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.lexend-500 {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.lexend-600 {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.lexend-700 {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

.lexend-800 {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
}

.lexend-900 {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
}


.dialog-open {
  overflow-y: hidden;
}